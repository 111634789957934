export enum SpotType {
  PARKING = 'parking',
  DOCKING = 'docking'
}

export enum SpotStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  ASSIGNED = 'assigned',
  UNKNOWN = 'unknown'
}

export enum SpotEventTypeEnum {
  ASSIGNMENT = 'assignment',
  RELEASE = 'release',
  CLOSE = 'close',
  OPEN = 'open',
  OTHER = 'other'
}

export interface ISpot {
  id: string;
  code: string;
  type: SpotType;
  isActive: boolean;
  spotAssignments?: ISpotAssignment[];
}

export interface ISpotAssignment {
  id: string;
  spotId: string;
  assetVisitId: string;
}

export function getSpotStatus(spot: ISpot): SpotStatus {
  if (spot.isActive === false) {
    return SpotStatus.CLOSED;
  }

  if (spot.isActive === true && !spot.spotAssignments?.length) {
    return SpotStatus.OPEN;
  }

  if (spot.isActive === true && spot.spotAssignments?.length) {
    return SpotStatus.ASSIGNED;
  }

  return SpotStatus.UNKNOWN;
}

/*
 * Sorts the spot list by spot code using Natural Sorting algorithm, IN-PLACE
 */
export function sortSpots(spots: ISpot[]): void {
  const extractNumber = (code: string) => {
    const match = code.match(/\d+/u);
    return match ? parseInt(match[0], 10) : Infinity;
  };

  const extractAlpha = (code: string) => {
    const match = code.match(/[a-zA-Z]+/u);
    return match ? match[0] : '';
  };

  if (
    Array.isArray(spots) &&
    spots.every(spot => typeof spot === 'object' && spot !== null && 'code' in spot)
  ) {
    spots.sort((a, b) => {
      const alphaA = extractAlpha(a.code);
      const alphaB = extractAlpha(b.code);
      if (alphaA === alphaB) {
        return extractNumber(a.code) - extractNumber(b.code);
      }
      return alphaA.localeCompare(alphaB);
    });
  }
}

export const YARD_SPOT_CODE_MAX_LENGTH = 5;
