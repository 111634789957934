export const secondsIn = {
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60
};

export const millisecondsIn = {
  week: 604800 * 1000,
  day: 86400 * 1000,
  hour: 3600 * 1000,
  minute: 60 * 1000,
  second: 1000
};
