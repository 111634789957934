/* eslint-disable no-await-in-loop */

/**
 * Executes an array of functions that return promises sequentially.
 *
 * @param resolvables - An array of functions that return promises.
 * @returns A promise that resolves to an array of resolved values.
 *
 * @example
 * // Example 1: Basic usage
 * const promise1 = () => Promise.resolve(1);
 * const promise2 = () => Promise.resolve(2);
 * const promise3 = () => Promise.resolve(3);
 *
 * allSync([promise1, promise2, promise3]).then((results) => {
 *   console.log(results); // [1, 2, 3]
 * });
 *
 * @example
 * // Example 2: Handling errors
 * const promise1 = () => Promise.resolve(1);
 * const promise2 = () => Promise.reject('error');
 * const promise3 = () => Promise.resolve(3);
 *
 * allSync([promise1, promise2, promise3]).then((results) => {
 *   console.log(results); // This will not be called
 * }).catch((error) => {
 *   console.error(error); // 'error'
 * });
 */
export async function allSync<T>(resolvables: (() => Promise<T>)[]): Promise<T[]> {
  const results: T[] = [];
  for (const resolvable of resolvables) {
    results.push(await resolvable());
  }
  return results;
}
